import { Paper, Table, TableCell, TableContainer, TableHead, TablePagination, TableBody, TableRow, CircularProgress, Stack } from "@mui/material";
import { useContext, useState } from "react";
import { useEffect } from "react";
import { Fragment } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { UserModuleContext } from "../context/UserModuleContext";

export const SearchResultTable = ({text}) =>{

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const {authState} = useOktaAuth();

    const columns = [
        {id: 'oktaId', label: "Okta ID", minWidth: 100, align: "center"},
        {id: 'wsuid', label: "WSU ID", minWidth: 100, align: "center"},
        {id: 'nid', label: "Network ID", minWidth: 170, align: "center"},
        {id: 'fname', label: "First Name", minWidth: 100, align: "center"},
        {id: 'lname', label: "Last Name", minWidth: 100, align: "center"},
        {id: 'altNames', label: "Previous Account Names", minWidth:100, align: "center"}
    ];

    const [state, setState] = useState({data: null, isLoaded: false, error: null});

    const {setUserModule} = useContext(UserModuleContext);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_AWS_API_GATEWAY_ENDPOINT}/user/search`,{
            method: "POST",
            signal: AbortSignal.timeout(360000),
            headers:{
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": authState.accessToken.accessToken
            },
            body: JSON.stringify({
                "searchValue": text
            })
        }).then((response) => response.json())
        .then(
            (result) => {
                const data = [];
                result.Rows.map((user) =>{
                    data.push({id: user.networkId,oktaId: user.oktaId, wsuid: user.wsuId, nid: user.networkId, fname: user.firstName, lname: user.lastName, altNames: user.previousAccountNames.toString()});
                    return null;
                });
                setState((prevState) => ({...prevState, isLoaded: true, data: data}));
            },
            (error) => {
                setState((prevState) => ({...prevState, isLoaded: true, error: error}));
            }
        )
    }, []);

 
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
      };

      const handleClick = (event) => {
        setUserModule((prev) => ({...prev, isOpen: true, userId: event.target.parentNode.getAttribute("data-item"), wsuId: event.target.parentNode.getAttribute("data-id")}));
      }

      function Row(props){
        const {row} = props;

        return(
            <Fragment key={row.nid + "-fragment"}>
                <TableRow hover={true} onClick={handleClick} key={row.nid} data-item={row.nid} data-id={row.wsuid} sx={{ '& > *': { borderBottom: 'unset' } }}>
                    {columns.map((column) =>{
                                const value = row[column.id];
                                return(
                                    <TableCell key={row.nid + column.id} align={column.align}>
                                        {column.format && typeof value === 'number'
                                        ? column.format(value) : value}
                                    </TableCell>
                                )
                            })}
                </TableRow>       
            </Fragment>
        )
      }


      if(!state.isLoaded){
        return (
        <div className="search-result-container"><CircularProgress style={{color: "#A60F2D" }} size={100} thickness={10}/></div>);
      }else if(state.error){
        return (<div className="search-result-container">Error: {state.error.message}</div>);
      }else if(state.data.length > 0){
        return (
            <div className="search-result-container">
                            <Paper key={"paper"} sx={{width: '80%', overflow: 'hidden', margin: "0px auto", border: "solid 1px"}}>
                <TableContainer key={"table-container"} sx={{ maxHeight: 440}}>
                    <Table key={"results-table"} stickyHeader aria-label="sticky table">
                        <TableHead key={"table-head"}>
                            <TableRow key={"header-row"}>
                            {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, backgroundColor: "#4D4D4D", color: "white" }}
                >
                  {column.label}
                </TableCell>
              ))}
                            </TableRow>
                        </TableHead>
                        <TableBody key={"table-body"}>
                            {
                                state.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) =>{
                                    return(
                                        <Row key={row.nid + "-row"} row={row}/>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination key={"table-pagination-control"} rowsPerPageOptions={[10,25,100]} 
                component="div"
                count={state.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}/>
            </Paper>
            </div>
          );
      }else{
        return (
            <div className="search-result-container">
                <p>No records found</p>
            </div>
        );
      }
    



}