import { useState, useEffect } from "react";
import { Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Button, Typography, CircularProgress } from "@mui/material";
import { Send } from "@mui/icons-material";
import { useOktaAuth } from "@okta/okta-react";

export const RemoveMFAFactor = ({identifier}) => {

    const [state, setState] = useState({isLoaded: false, error: null, data: null});


    const {authState} = useOktaAuth();
   
    useEffect(() => {
        fetch(`${process.env.REACT_APP_AWS_API_GATEWAY_ENDPOINT}/user/factors/list`, {
            method: "POST",
            signal: AbortSignal.timeout(360000),
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": authState.accessToken.accessToken
            },
            body: JSON.stringify({
                "username": identifier
            })
        })
        .then((res) => res.json())
        .then(
            (result) => {
                setState((prevState) => ({
                    ...prevState,
                    isLoaded: true,
                    data: result
                }));
            },
            (error) => {
                setState((prevState) => ({
                    ...prevState,
                    isLoaded: true,
                    error: error
                }));
            }
        )
    }, []);

    const removeFactor = async(factorId, factorName) => {

        setState((prev) => ({...prev, isLoaded: false}));

        fetch(`${process.env.REACT_APP_AWS_API_GATEWAY_ENDPOINT}/user/factors/remove`,{
            body: JSON.stringify({
                callerId: authState.accessToken.claims.sub,
                userId: identifier,
                factorId: factorId, 
                factorName: factorName
            }),
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": authState.accessToken.accessToken
            }
        }).then((res) => res.json())
        .then((result) => {
            if(result.statusCode === 200){
                setState((prev) => ({...prev, isLoaded: true, data: result}));
            }else{
                setState((prev) => ({...prev, isLoaded: true, error: {message: result.message}}));
            }

        },(error) => {
            setState((prev) => ({...prev, isLoaded: true, error: error}));
        })
    }


    if(!state.isLoaded){
        return (<div id="container-div"><CircularProgress id="progress" size={100} thickness={10}/></div>);
    }else if(state.validating){
        return (<div id="container-div"><CircularProgress id="progress" size={100} thickness={10}/></div>);
    }else if(state.error){
        return (<div id="container-div">
            <p>{state.error.message}</p>
        </div>);
    }else{
        return (
            <div id="container-div">
                <Paper key={"mfa-paper"} sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer key={"mfa-container"} sx={{ maxHeight: 440 }}>
                        <Typography key={"mfa-container-typography"} variant="h6" gutterBottom component="div">
                            Modify MFA Factors
                        </Typography>
                        <Table key={"mfa-table"} stickyHeader aria-label="sticky table">
                            <TableHead key={"mfa-table-head"}>
                                <TableRow key={"mfa-table-header-row"}>
                                    <TableCell key="factor-name">Factor Name</TableCell>
                                    <TableCell key="factor-type">Factor Type</TableCell>
                                    <TableCell key="factor-id">Factor ID</TableCell>
                                    <TableCell key="device-type">Device Type</TableCell>
                                    <TableCell key="date-created">Date Created</TableCell>
                                    <TableCell key="button"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody key="mfa-table-body">
                                {state.data.factors
                                    .map((row) => {
                                        if(row.factorType !== "email"){
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                    <TableCell key={row.code + "-factor-name"}>{row.profile.name}</TableCell>
                                                    <TableCell key={row.code + "-factor-type"}>{row.factorType}</TableCell>
                                                    <TableCell key={row.code + '-id'}>{row.id}</TableCell>
                                                    <TableCell key={row.code + '-device-type'}>{row.profile.deviceType}</TableCell>
                                                    <TableCell key={row.code + '-created-date'}>{row.created}</TableCell>
                                                    <TableCell key={row.code + "-button-cell"}><Button key={row.codde + "-remove-button"} variant="contained" endIcon={<Send />} id="right-button" onClick={(e) => removeFactor(row.id, row.profile.name)}>Remove MFA Factor</Button></TableCell>
                                                </TableRow>
                                            );
                                        }
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </div>
        )
    }

}