import { Paper, TableCell, TableContainer, TableBody, TableRow, Table, CircularProgress, Button, IconButton } from "@mui/material"
import { useContext, useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { UserModuleContext } from "../context/UserModuleContext";

export const PersonTable = ({identifier}) =>{

    const [state, setState] = useState({ isLoaded: false, error: null});

    const rows = [];

    const {authState} = useOktaAuth();

    const {userModule, setUserModule} = useContext(UserModuleContext);
    
    useEffect(() => {
        if(userModule.isOpen){
            fetch(`${process.env.REACT_APP_AWS_API_GATEWAY_ENDPOINT}/user/info`, {
                method: "POST",
                headers: {
                    "Authorization": authState.accessToken.accessToken,
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify({
                    "userId": identifier
                })
            })
            .then(res => {
                return res.json();
            })
            .then(
              (result) => {
                setState((prevState) => ({...prevState, isLoaded: true}));
                setUserModule((prev) => ({...prev, isRestricted: result["Restricted From Modification In Tech Tools"], isLoaded: true, userData: result}));
              }
            ).catch(error => {
                let timer1 = setTimeout(() => fetch(`${process.env.REACT_APP_AWS_API_GATEWAY_ENDPOINT}/user/info`, {
                    method: "POST",
                    headers: {
                        "Authorization": authState.accessToken.accessToken,
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    },
                    body: JSON.stringify({
                        "userId": identifier
                    })
                })
                .then(res => {
                    return res.json();
                })
                .then(
                  (result) => {
                    setState((prevState) => ({...prevState, isLoaded: true}));
                    setUserModule((prev) => ({...prev, isRestricted: result["Restricted From Modification In Tech Tools"], isLoaded: true, userData: result}));
                  }
                ), 30000);
                return () => {
                    clearTimeout(timer1);
                }
            })
        }
    }, []);

    for(const key in userModule.userData){
        if(key !== "statusCode"){
            let value = userModule.userData[key];
            if(Array.isArray(value)){
                value = value.toString();
            }
            if(typeof value === "boolean"){
                value = value.toString();
            }
            rows.push(<TableRow key={key + "-row"}><TableCell key={key + "-cell"}>{key}</TableCell><TableCell key={key + "-value-" + userModule.userData[key]}>{value}</TableCell></TableRow>);
        }
    }

    if(!state.isLoaded){
        return (<div id="container-div"><CircularProgress style={{color: "#A60F2D" }} size={100} thickness={10}/></div>);
    }else if(state.error){
        return (<div id="container-div">Error: {state.error.message}</div>);
    }else{
        return(
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 800}} aria-label="person-table">
                    <TableBody>{rows}</TableBody>
                </Table>
            </TableContainer>
        )
    }
}