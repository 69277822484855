import { Close, Send } from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";
import { useState, useContext, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { ModuleContext } from "../context/ModuleContext";
import { UserModuleContext } from "../context/UserModuleContext";

export const AccountStatus = ({identifier}) => {

    const [state, setState] = useState({error: null, waiting: false, userStatus: null});

    const {setModule} = useContext(ModuleContext);

    const {userModule, setUserModule} = useContext(UserModuleContext);
    
    const {authState} = useOktaAuth();

    useEffect(() => {
        setState((prev) => ({...prev, userStatus: userModule.userData["Okta Status"]}));
    }, []);
    
    const suspendAccount = async() => {

        setState((prev) => ({...prev, triggered: true, waiting: true}));

        await fetch(`${process.env.REACT_APP_AWS_API_GATEWAY_ENDPOINT}/user/suspend`,{
            body: JSON.stringify({
                networkId: identifier,
                comment: `Account ${identifier} suspended`,
                callerId: authState.accessToken.claims.sub
            }),
            method: "POST",
            signal: AbortSignal.timeout(360000),
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": authState.accessToken.accessToken
            }

        })
        .then((res) => res.json())
        .then((result) => {
            setState((prev) => ({...prev, waiting: false, userStatus: "SUSPENDED"}));
            let newUserData = userModule.userData;
            newUserData["Okta Status"] = "SUSPENDED";
            setUserModule((prev) => ({...prev, userData: newUserData}));
        },
        (error) => {
            console.error(error);
            setState((prev) => ({...prev, waiting: false, result: "Attempt to suspend account failed"}));
        })

    }

    const unsuspendAccount = async() => {
        setState((prev) => ({...prev, triggered: true, waiting: true}));

        await fetch(`${process.env.REACT_APP_AWS_API_GATEWAY_ENDPOINT}/user/unsuspend`,{
            body: JSON.stringify({
                networkId: identifier,
                comment: `Account ${identifier} unsuspended`,
                callerId: authState.accessToken.claims.sub
            }),
            signal: AbortSignal.timeout(360000),
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": authState.accessToken.accessToken
            }

        })
        .then((res) => res.json())
        .then((result) => {
            setState((prev) => ({...prev, waiting: false, userStatus: "ACTIVE"}));
            let newUserData = userModule.userData;
            newUserData["Okta Status"] = "ACTIVE";
            setUserModule((prev) => ({...prev, userData: newUserData}));
        },
        (error) => {
            console.error(error);
            setState((prev) => ({...prev, waiting: false, error: error}));
        })
    }
    
    if(state.waiting){
        return(
            <div id="container-div"><CircularProgress key="account-status-loading" id="progress" size={100} thickness={10}/></div>
        );
    }else if(state.error){
        return(<div id="container-div">
            <p>{state.error.message}</p>
        </div>);
    }else if(state.userStatus === "ACTIVE"){
        return(
            <div key="active-status-container" id="container-div">
            <p key="suspend-account-header">{`Suspend account ${identifier}?`}</p><br />
            <Button key={identifier + "-suspend-account"} variant="contained" id="left-button" endIcon={<Send />} onClick={(e) => suspendAccount()}>Yes</Button>
            <Button key={identifier + "-dont-suspend"} variant="contained" id="right-button" endIcon={<Close />} onClick={(e) => setModule((prev) => ({...prev, isVisible: false}))}>No</Button>
            </div>
        );
    }else if(state.userStatus === "SUSPENDED"){
        return(
            <div key="suspended-status-container" id="container-div">
                <p key="unsuspend-account-header">{`Unsuspend account ${identifier}?`}</p><br />
                <Button key={identifier + "-unsuspend-account"} variant="contained" id="left-button" endIcon={<Send />} onClick={(e) => unsuspendAccount()}>Yes</Button>
                <Button key={identifier + "-dont-unsuspend"} variant="contained" id="right-button" endIcon={<Close />} onClick={(e) => setModule((prev) => ({...prev, isVisible: false}))}>No</Button>
            </div>
        );
    }else{
        return(
            <div key="not-in-status-container" id="container-div">
            <p key={identifier + "-user-cant-action"}>{`User ${identifier} is in account status ${state.userStatus} and is not eligible for this action`}</p>
        </div>
        );
    }

}