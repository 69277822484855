import { useContext, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { CircularProgress, FormGroup, FormControlLabel, Checkbox, TextField, Button, Table, TableBody, TableRow, TableCell, ThemeProvider, useTheme} from "@mui/material";
import { UserModuleContext } from "../context/UserModuleContext";
import { Send } from "@mui/icons-material";
import { InputTheme } from "../themes/InputTheme";

export const SpecialHideFromGal = ({userId}) => {

    const {userModule, setUserModule} = useContext(UserModuleContext);

    const [state, setState] = useState({waiting: false, message: null, error: null, reason: ""});

    const [checked, setChecked] = useState(userModule.userData["Special Hide From GAL"] === true);
    
    const {authState} = useOktaAuth();

    const outerTheme = useTheme();
    
    const setSpecialHideFromGal = async() => {
        setState((prev) => ({...prev, waiting: true}))
        await fetch(`${process.env.REACT_APP_AWS_API_GATEWAY_ENDPOINT}/user/special-hide-from-gal`,{
            body: JSON.stringify({
                userId: userId,
                callerId: authState.accessToken.claims.sub,
                specialHideFromGal: checked,
                reason: state.reason
            }),
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": authState.accessToken.accessToken
            }

        })
        .then((res) => {
            console.log(res.status);
            return ({responseCode: res.status, data: res.json()})
        })
        .then((result) => {
            console.log(result);
            if(result.responseCode === 200){
                let newData = userModule.userData
                newData["Special Hide From GAL"] = checked;
                setUserModule((prev) => ({...prev, userData: newData}));
                setState((prev) => ({...prev, message: "Special Hide From GAL updated" , waiting: false}));
            }else{
                setState((prev) => ({...prev, message: "Failed to udpate Special Hide from GAL" ,error: result.data, waiting: false}));
            }
        },
        (error) => {
            setState((prev) => ({...prev, message: "Failed to udpate Special Hide from GAL" ,error: error, waiting: false}));
        })
    }

    const handleTextChange = ({message}) => {
        setState((prev) => ({...prev, reason: message}));
    }

    
    if(state.waiting){
        return(
            <div key="shfg-waiting-div" id="container-div"><CircularProgress key="reinvite-waiting" id="progress" size={100} thickness={10}/></div>
        );
    }else if(state.message === "Special Hide From GAL updated"){
        return(
            <div key="response-message" id="container-div"><p key="message">{state.message}</p></div>
        );
    }else if(state.error){
        return(
            <div id="container-div">
                <ThemeProvider theme={InputTheme(outerTheme)}>
                <Table key="special-hide-from-gal-table">
                    <TableBody key="special-hide-from-gal-table-body">
                        {userModule.userData["Special Hide From GAL"] === true && <TableRow key="HRS-Row"><TableCell align="center" key="HRS-Cell"><p>Please verify with HRS before removing Special Hide From GAL</p></TableCell></TableRow>}
                        <TableRow key="shfg-row"><TableCell key="shfg-cell"><FormGroup align="center"><FormControlLabel control={<Checkbox id="special-hide-from-gal-checkbox" style={{color: "#A60F2D"}} checked={checked} onChange={(e) => setChecked(e.target.checked)} />} label="Special Hide From GAL"/></FormGroup></TableCell></TableRow>
                        {!(userModule.userData["Special Hide From GAL"] === true) && <TableRow key="shfg-reason-row"><TableCell align="center" key="shfg-reason-cell"><TextField key="shfg-message" required id="module-input" value={state.message} onChange={(e) => handleTextChange(e.target.value)} label="Reason"/></TableCell></TableRow>}
                        <TableRow key="shfg-button"><TableCell align="center" key="shfg-button-cell"><Button key="submit-button" variant="contained" id="middle-button" endIcon={<Send />} onClick={(e) => setSpecialHideFromGal()}>Update Special Hide From GAL</Button></TableCell></TableRow>
                    </TableBody>
                </Table>
                </ThemeProvider>
            <p>{state.message}</p>
            </div>
        );
    }else{
        return(
            <div id="container-div">
                <ThemeProvider theme={InputTheme(outerTheme)}>
                <Table key="special-hide-from-gal-table">
                    <TableBody key="special-hide-from-gal-table-body">
                        {userModule.userData["Special Hide From GAL"] === true && <TableRow key="HRS-Row"><TableCell align="center" key="HRS-Cell"><p>Please verify with HRS before removing Special Hide From GAL</p></TableCell></TableRow>}
                        <TableRow key="shfg-row"><TableCell key="shfg-cell"><FormGroup align="center"><FormControlLabel control={<Checkbox id="special-hide-from-gal-checkbox" style={{color: "#A60F2D"}} checked={checked} onChange={(e) => setChecked(e.target.checked)} />} label="Special Hide From GAL"/></FormGroup></TableCell></TableRow>
                        {!(userModule.userData["Special Hide From GAL"] === true) && <TableRow key="shfg-reason-row"><TableCell align="center" key="shfg-reason-cell"><TextField key="shfg-message" required id="module-input" value={state.message} onChange={(e) => handleTextChange(e.target.value)} label="Reason"/></TableCell></TableRow>}
                        <TableRow key="shfg-button"><TableCell align="center" key="shfg-button-cell"><Button key="submit-button" variant="contained" id="middle-button" endIcon={<Send />} onClick={(e) => setSpecialHideFromGal()}>Update Special Hide From GAL</Button></TableCell></TableRow>
                    </TableBody>
                </Table>
                </ThemeProvider>
            </div>
        );
    }


}