import { createContext } from "react";

export const UserModuleContext = createContext({
    userModule: {
        isOpen: false,
        userId: null,
        wsuId: null,
        isLoaded: false,
        isRestricted: false,
        userData: null
    },
    setUserModule: () => ({})
});