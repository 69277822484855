import { Send } from "@mui/icons-material";
import { Button, CircularProgress, useTheme, ThemeProvider, FormControl, InputLabel, Select, MenuItem, FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";
import { useContext, useEffect, useState } from "react";
import { UserModuleContext } from "../context/UserModuleContext";
import { InputTheme } from "../themes/InputTheme";

export const NidRename = ({userId}) => {

    const [state, setState] = useState({isLoaded: false, error: null, success: false, nids: null});

    const {authState} = useOktaAuth();

    const [text, setText] = useState(userId);

    const outerTheme = useTheme();

    const {setUserModule} = useContext(UserModuleContext);

    const updateNid = async() => {
        setState((prevState) => ({...prevState, isLoaded: false, error: null, success: false}));    
        await fetch(`${process.env.REACT_APP_AWS_API_GATEWAY_ENDPOINT}/user/nid/rename`,{
            method: "POST",
            signal: AbortSignal.timeout(360000),
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": authState.accessToken.accessToken
            },
            body: JSON.stringify({
                "userId": userId,
                "newId": text,
                "callerId": authState.accessToken.claims.sub
            })
        })
        .then((res) => {
            return res.status
        })
        .then((result) => {
            if(result === 200){
                setState((prevState) => ({...prevState, isLoaded: true, success: true}));
                setUserModule((prev) => ({...prev, userId: text}));
            }else{
                setState((prevState) => ({...prevState, isLoaded: true, error: {message: "Failed to rename NID"}}));
            }
        },
        (error) => {
            setState((prevState) => ({...prevState, isLoaded: true, error: error}));
        });
    }

    useEffect(() => {
        fetch(`${process.env.REACT_APP_AWS_API_GATEWAY_ENDPOINT}/user/nid/list`,{
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": authState.accessToken.accessToken
            },
            body: JSON.stringify({
                "networkId": userId
            })
        })
        .then((res) => res.json())
        .then((result) => {
            setState((prev) => ({ ...prev, nids: result.nidList, isLoaded: true}));
            setText(result.nidList[0]);
        },
        (error) => {
            setState((prevState) => ({...prevState, isLoaded: true, error: error}));
        })
    }, []);



    if(!state.isLoaded){
        return (<div key="nid-rename-load-cont" id="container-div"><CircularProgress key="nid-rename-loading" id="progress" size={100} thickness={10}/></div>);
    }else if(state.error){
        return (<div key="nid-rename-error-cont" id="container-div"><p key="nid-rename-error-message">{state.error.message}</p></div>);
    }else if(state.success){
        return (<div key="nid-rename-container" id="container-div">
              <div key="nid-rename-success-cont" id="container-div">
                <p key="nid-rename-success">{"NID/FID " + userId + " has been renamed to " + text}</p>
            </div>      
        </div>);
    }
    else{
        return (<div key="nid-rename-container" id="container-div">
            <ThemeProvider theme={InputTheme(outerTheme)}>
            <FormControl fullWidth>
            <FormLabel id={"nid-button-groups-label"}>NID Options</FormLabel>
                <RadioGroup defaultValue={state.nids[0]} name="nid-button-group" onChange={(e) => setText(e.target.value)}>
                    {state.nids.map((nid) => {
                        return (<FormControlLabel key={nid + "-option"} value={nid} control={<Radio />} label={nid} />);
                    })}
                </RadioGroup>
            </FormControl>
            <Button key="nid-rename-send-button" variant="contained" id="right-button" endIcon={<Send />} onClick={(e) => updateNid()}>Update NID/FID</Button>
            </ThemeProvider>
        </div>);
    }


}